import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CV = (props: PageProps) => (
  <Layout>
    <SEO title="London Front End Developer CV" />
    <h2>Curriculum Vitae</h2>
    <div className="intro">
      <p>
        Here are some details of my professional and personal life. Please
        contact me with any enquiries.
      </p>
    </div>
    <div className="main">
      <h3>Experience</h3>
      <p>
        <b>
          Senior Software Engineer, <a href="//www.qvcuk.com">QVC</a>, London,
          June 2013 - Present
        </b>
      </p>
      <p>
        Sitting within a global development team, the role involves developing
        front-end code to deliver new content and functionality for QVC's
        digital platforms.
      </p>
      <p>My current responsibilities include:</p>
      <ul>
        <li>
          Design scalable and maintainable front-end architectures across a
          global codebase, making key decisions on frameworks, libraries, and
          tools with developers based in the US, Germany, Italy and Japan.
        </li>
        <li>
          Conduct thorough code reviews, enforce coding standards, and ensure
          best practices are followed.
        </li>
        <li>
          Mentor junior developers, provide technical guidance, and support
          continuous learning within the team.
        </li>
        <li>
          Work closely with UX/UI designers, back-end developers, product
          managers, and other stakeholders to translate requirements into
          functional, user-friendly interfaces.
        </li>
        <li>
          Identify and implement strategies to optimize web application
          performance, including improving load times, responsiveness, and
          accessibility.
        </li>
        <li>
          Keep up-to-date with the latest front-end technologies, frameworks,
          and best practices, and incorporate them into the development process
          where applicable.
        </li>
        <li>
          Develop and maintain automated testing and deployment pipelines to
          ensure continuous integration and delivery (CI/CD) for front-end
          components.
        </li>
        <li>
          Ensure that web applications are compatible with a wide range of
          browsers and devices, providing a consistent user experience across
          platforms.
        </li>
        <li>
          Implement front-end security best practices, including protecting
          against XSS, CSRF, and other vulnerabilities.
        </li>
        <li>
          Create and maintain comprehensive documentation for front-end
          codebases, architecture, and best practices.
        </li>
        <li>
          Participate in Agile ceremonies, contribute to sprint planning, and
          ensure the front-end team delivers on sprint goals.
        </li>
        <li>
          Evaluate and integrate new tools and workflows to improve the
          efficiency of the development process.
        </li>
      </ul>
      <p>
        For additional information please see my{" "}
        <Link to="/portfolio/">portfolio</Link> and{" "}
        <Link to="#skills">skills</Link>.
      </p>
      <p>
        <b>
          Front End Developer,{" "}
          <a href="//www.holidayextras.co.uk">Holiday Extras</a>, Newingreen,
          June 2009 - June 2013
        </b>
      </p>
      <p>
        The role involved construction and maintenance of sites within the
        company's short breaks division, such as{" "}
        <a href="//www.play-and-stay.co.uk">Play and Stay</a> and{" "}
        <a href="//www.show-and-stay.co.uk">Show and Stay</a>. Through late 2009
        I formed a key part of the team responsible for setting up{" "}
        <a href="//www.legolandholidays.de">Legoland Holidays</a> in Germany. I
        was promoted from 'Junior Template Builder' and in 2010 I led the
        development of the{" "}
        <a href="//www.thorpebreaks.co.uk">Thorpe Park Breaks</a> redesign, and
        created Eden Project Breaks. In 2011 I launched a redesign for{" "}
        <a href="//www.paultonsbreaks.com/">Paultons Breaks</a> and continued to
        deliver improvements for the <a href="/portfolio.php">portfolio</a> of
        major UK theme park ticket and hotel package sites daily.
      </p>
      <ul>
        <li>
          Expected to deliver elegant and sophisticated interfaces using HTML5,
          CSS3 and JavaScript, handling bookings from a variety of browsers and
          mobile devices.
        </li>
        <li>
          Developed expert knowledge of cross browser compatibility issues and
          resolutions.
        </li>
        <li>
          Consistently demonstrate problem solving and initiative across a range
          of technical projects within a highly focused team.
        </li>
        <li>
          Perform research into new tools and technologies for both existing and
          future products.
        </li>
        <li>
          Developed an excellent working knowledge of the web development
          process within a commercial environment, including Kanban scheduling.
        </li>
      </ul>
      <p>
        <b>
          Study Manager, <a href="//www.pfizer.com">Pfizer</a>, Sandwich,
          September 2007 - August 2008
        </b>
      </p>
      <p>
        The position of Study Manager involved two key areas. The core role,
        study management, can be broadly defined as including any task in order
        to move a clinical trial forward. These tasks can involve writing
        protocols, arranging investigator meetings, ordering drug supplies, and
        collating study results amongst many others. In addition, I became
        involved in a number of technical roles during the year. There was a
        strong emphasis on personal development throughout the placement.
      </p>
      <ul>
        <li>
          Gained experience of working within a corporate environment,
          displaying a professional attitude and adaptability.
        </li>
        <li>Developed formal presentation and interpersonal skills.</li>
        <li>
          Demonstrated initiative, decision making and problem solving skills
          within a number of different teams.
        </li>
      </ul>
      <p>
        <b>
          Campus Tour Guide, <a href="//www.kent.ac.uk">University of Kent</a>,
          Canterbury, September 2006 - June 2009
        </b>
      </p>
      <p>
        The role involved directing visitors around the university campus and
        outlining the facilities available on site. I worked on a number of Open
        Days and was a professional, approachable ambassador for the
        organisation.
      </p>
      <ul>
        <li>Developed public speaking and group organisational skills.</li>
        <li>Worked with other guides as a team member.</li>
      </ul>

      <h3>Education</h3>
      <p>
        <b>
          <a href="//www.cs.kent.ac.uk/">
            BSc (Hons) Computer Science with a Year in Industry
          </a>
          , <a href="//www.kent.ac.uk">University of Kent</a>, Canterbury,
          2005-2009
        </b>
      </p>
      <ul>
        <li>
          Awarded Upper Second Class Honours and attended degree congregation at
          Canterbury Cathedral on 16th July 2009.
        </li>
        <li>
          Gained academic credit through a placement at Pfizer during 2007/2008.
        </li>
      </ul>
      <p>
        <b>Pent Valley Technology College, Folkestone, 1998-2005</b>
      </p>
      <ul>
        <li>
          Achieved five A levels, including three at 'A' grade, for a total of
          460 UCAS points.
        </li>
        <li>Achieved ten GCSEs, including five at 'A' grade'.</li>
      </ul>

      <h3 id="skills">Skills and interests</h3>
      <ul>
        <li>
          Strong knowledge of modern front end web and mobile technologies and
          development techniques, including:
        </li>
        <ul>
          <li>HTML5 and semantic markup</li>
          <li>
            Advanced CSS3, SASS, and LESS; proficiency with modern CSS
            frameworks (e.g., Tailwind, Bootstrap)
          </li>
          <li>
            JavaScript/TypeScript expertise; advanced knowledge of frameworks
            and libraries (e.g., React, Angular, Vue, Node.js)
          </li>
          <li>
            Proficient in JavaScript testing frameworks (e.g., Jest, Mocha,
            Cypress)
          </li>
          <li>
            React Native for cross-platform mobile development (iOS and Android)
          </li>
          <li>GraphQL for efficient data querying and manipulation</li>
          <li>
            Experience with modern automation and build tools (e.g., Webpack,
            Gulp, Vite)
          </li>
          <li>
            Deep understanding of cross-browser compatibility and CORS in modern
            web development
          </li>
          <li>Responsive web design and mobile-first development</li>
          <li>Search engine optimization (SEO) best practices</li>
          <li>Web accessibility (WCAG compliance) and inclusive design</li>
        </ul>
        <li>
          I have experience programming in a number of other languages including
          Java, C, C++, Python, Occam, Haskell and Prolog. Through my solid
          understanding of programming concepts I have a demonstrated ability to
          pick up new languages quickly. I frequently take coding challenges on{" "}
          <Link to="https://www.codewars.com/">Codewars</Link> to strengthen my
          coding skills.
        </li>
        <li>
          I enjoy installing, upgrading and maintaining computer hardware, and
          have a keen enthusiasm for staying up to date with the latest tech
          news.
        </li>
        <li>
          I have a strong interest in history and architecture and I'm able to
          combine this with an interest in photography and travel.
        </li>
        <li>I hold a full manual UK driving licence, and own a car.</li>
      </ul>
    </div>
    <Link to="/">Return to homepage</Link>
  </Layout>
)

export default CV
